<template>
	<div class="TradeDetail">
		
		<div class="Top">
			<div class="Left">
				流水详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="TradeDetail != null && TradeDetail.Id != 'add'">
					<span>流水ID</span>
					<em>
						{{TradeDetail.Id}}
					</em>
				</li>
				
				<li>
					<span>流水类型</span>
					<em  v-if="TradeDetail != null">
						{{TradeDetail.TypeName}}
					</em>
				</li>
				
				<li v-if="TradeDetail  != null">
					<span>关联用户Hid</span>
					<em>
						{{TradeDetail.UserHid}}
					</em>
				</li>
				
				<li>
					<span>部队Id</span>
					<em>
						{{TradeDetail.TroopId}}
					</em>
				</li>
				
				<li>
					<span>充值ID</span>
					<em>
						{{TradeDetail.OrderId}}
					</em>
				</li>
				
				<li>
					<span>提现Id</span>
					<em>
						{{TradeDetail.WithdrawId}}
					</em>
				</li>
				
				<li>
					<span>订单Id</span>
					<em>
						{{TradeDetail.OrderId}}
					</em>
				</li>
				
				<li>
					<span>金额</span>
					<em>
						￥{{TradeDetail.Amount}} 
					</em>
				</li>
			
				
				<li>
					<span>流水备注</span>
					<em>
						{{TradeDetail.Remark}}
					</em>
				</li>
				

			
				
				<li>
					<span>审核时间</span>
					<em>
						{{TradeDetail.AuditTime}}
					</em>
				</li>
				
				<li>
					<span>审核说明</span>
					<em>
						{{TradeDetail.AuditRemark}}
					</em>
				</li>
				
				
				<li v-if="TradeDetail != null && TradeDetail.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{TradeDetail.CreatedAt}}
					</em>
				</li>
				
				<li v-if="TradeDetail != null && TradeDetail.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{TradeDetail.UpdatedAt}}
					</em>
				</li>
				
				<li v-if="TradeDetail != null">
					<span>流水状态</span>
					<em>
						{{TradeDetail.StatusName}}
					</em>
				</li>
				
			</div>
		</div>
		


	</div>
</template>

<script>
	import {Select,Option} from 'element-ui'
	export default {
	  name: 'TradeDetail',
	  props: {
	  },
	  data() {
	      return {
			  TradeDetail:{
				  Id:'',
				  TradeDetailAccountType:'',
				  TradeDetailAccountTypeName:'',
				  TradeDetailBankName:'',
				  TradeDetailHolder:'',
				  TradeDetailNo:'',
				  Status:50,
				  StatusName:'',
				  AuditTime:'',
				  AuditorHid:'',
				  AuditRemark:'',
				  AuditProof:'',
				  TradeDetailAmount:'',
				  TradeDetailCommissio:'',
				  TradeDetailTotal:'',
				  CreatedAt:'',
				  UpdatedAt:'',
			  },
			  RefuseDialog:false,
			  AuditRemark:'',
			  AuditProof:'',//审核凭证图片
			  CheckDialog:false,//提现确认框
			  TradeDetailPayAccountList:[],//提现付款账号列表
			  PayAccountId:'',
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少编号ID，页面无法工作')
			return
		}
		this.GetTradeDetail(this.$route.params.Id)
	  },
	  methods:{
		    GetTradeDetail(_id){
		  		let data = {Service:'Finance',Class: 'FinanceTradeDetail',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.TradeDetail = res.Data
		  		})
		    },
	},
}
</script>

<style scoped>
.TradeDetail{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.TradeDetail .Top{
	display: flex;
	align-items: center;
}
.TradeDetail .Top .Left{
	
}
.TradeDetail .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.TradeDetail .List{
	margin-top: 20px;	
}
.TradeDetail .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.TradeDetail .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.TradeDetail .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.TradeDetail .List .One li  em{
	flex: 1;
}
.TradeDetail .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
